import React from 'react'
import GenerateHtml from '../generateHtml'

const Robit = () => {
    return (
        <div>
            < GenerateHtml 
            reviewsColor= "#000000"
            styles=" "
            displayingHtmlFor = "Rob It"
            />
        </div>
        
    )
}

export default Robit
