import React from 'react'
import GenerateHtml from '../generateHtml'

const Wilco = () => {
    return (
        <div>
            < GenerateHtml 
            reviewsColor= "#30a948"
            styles=" "
            displayingHtmlFor = "Wilco"
            />
        </div>
    )
}

export default Wilco
