import React from 'react'
import GenerateHtml from '../generateHtml'

const Dgh = () => {
    return (
        <div>
            < GenerateHtml 
            reviewsColor= "#f15a24"
            styles=" "
            displayingHtmlFor = "Dgh"
            />
        </div>
    )
}

export default Dgh
