import React from "react";
import GenerateHtml from "../generateHtml";

const Techy = () => {
  return (
    <div>
      <GenerateHtml reviewsColor="#000000"
       styles=" "
       displayingHtmlFor = "Techy Guys"
        />
    </div>
  );
};

export default Techy;
